import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const Supporter = lazy(() => import('./supporter'));

const AddPerson = lazy(() => import('./person/add'));
const ListPerson = lazy(() => import('./person/list'));
const Agenda = lazy(() => import('./calendar'));

const AddInstitutions = lazy(() => import('./institutions/add'));
const ListInstitutions = lazy(() => import('./institutions/list'));
const Birthdays = lazy(() => import('./birthdays/list'));
const Tasks = lazy(() => import('./tasks/list'));
const AddTasks = lazy(() => import('./tasks/add'));
const ListDemand = lazy(() => import('./demand/list'));
const AddDemand = lazy(() => import('./demand/add'));

const AddAmendment = lazy(()=> import('./amendment/add'));
const ListAmendment = lazy(()=> import('./amendment/list'));

const ListUser = lazy(() => import('./user/list'));
const AddUser = lazy(() => import('./user/add'));

const ListGroup = lazy(() => import('./register/grupo/list/Grupo'));
const ListCategory = lazy(() => import('./register/categoria/list/Categoria'));
const ListFunction = lazy(() => import('./register/funcao/list/Funcao'));
const ListOccupation = lazy(() => import('./register/ocupacao/list/Ocupacao'));
const ListCounty = lazy(() => import('./register/municipio/list'));
const AddCounty = lazy(() => import('./register/municipio/add'));
const ListCharge = lazy(() => import('./register/cargo/list/Cargo'));
const ListAffiliation = lazy(() => import('./register/partido/list/Partido'));

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/supporters-map`}
          component={lazy(() => import(`./maps/supporters-map`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/demand-map`}
          component={lazy(() => import(`./maps/demand-map`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/amendment-map`}
          component={lazy(() => import(`./maps/amendment-map`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/supporter`}
          component={lazy(() => import(`./supporter`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/person`}
          component={ListPerson}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/person/add`}
          component={AddPerson}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/person/add/:id`}
          component={AddPerson}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/demanda`}
          component={ListDemand}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/demanda/add`}
          component={AddDemand}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/demanda/add/:id`}
          component={AddDemand}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/emenda`}
          component={ListAmendment}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/emenda/add`}
          component={AddAmendment}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/emenda/add/:id`}
          component={AddAmendment}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/institutions`}
          component={ListInstitutions}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/institutions/add`}
          component={AddInstitutions}
          exact
        />
         <Route
          path={`${APP_PREFIX_PATH}/institutions/add/:id`}
          component={AddInstitutions}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/usuario`}
          component={ListUser}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/usuario/add`}
          component={AddUser}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/usuario/add/:uuid`}
          component={AddUser}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/register/grupo/list/Grupo`}
          component={ListGroup}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/register/categoria/list/Categoria`}
          component={ListCategory}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/register/funcao/list/Funcao`}
          component={ListFunction}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/register/ocupacao/list/Ocupacao`}
          component={ListOccupation}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/register/municipio/list`}
          component={ListCounty}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/register/municipio/add`}
          component={AddCounty}
          exact
        />

        <Route
          path={`${APP_PREFIX_PATH}/register/cargo/list/Cargo`}
          component={ListCharge}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/register/partido/list/Partido`}
          component={ListAffiliation}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/birthdays`}
          component={Birthdays}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/tasks`}
          component={Tasks}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/tasks/add`}
          component={AddTasks}
          exact
        />
        <Redirect
          exact
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/home`}
        />
        <Route
          path={`${APP_PREFIX_PATH}/calendar`}
          component={Agenda}
          exact
        />
        
        
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
