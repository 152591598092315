import {
  DashboardOutlined,
  UserOutlined,
  HomeOutlined,
  PieChartOutlined,
  ReadOutlined,
  EditOutlined,
  EnvironmentOutlined,
  TagOutlined,
  GiftOutlined,
  CalendarOutlined,
  BellOutlined,
  UngroupOutlined,
  ApartmentOutlined,
  LinkOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'person',
    path: `${APP_PREFIX_PATH}/person`,
    title: 'sidenav.pages.person',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'institutions',
    path: `${APP_PREFIX_PATH}/institutions`,
    title: 'sidenav.pages.institutions',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'supporter',
    path: `${APP_PREFIX_PATH}/supporter`,
    title: 'sidenav.pages.supporter',
    icon: PieChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'demands',
    path: `${APP_PREFIX_PATH}/demanda`,
    title: 'sidenav.pages.demands',
    icon: ReadOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'tasks',
    path: `${APP_PREFIX_PATH}/tasks`,
    title: 'sidenav.pages.tasks',
    icon: EditOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'maps-notitle',
    path: '',
    title: '',
    icon: EnvironmentOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'maps',
        path: `${APP_PREFIX_PATH}/maps`,
        title: 'sidenav.pages.maps',
        icon: EnvironmentOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'supporters-map',
            path: `${APP_PREFIX_PATH}/supporters-map`,
            title: 'sidenav.pages.supporters-map',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'demand-map',
            path: `${APP_PREFIX_PATH}/demand-map`,
            title: 'sidenav.pages.demand-map',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'amendment-map',
            path: `${APP_PREFIX_PATH}/amendment-map`,
            title: 'sidenav.pages.amendment-map',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'amendments',
    path: `${APP_PREFIX_PATH}/emenda`,
    title: 'sidenav.pages.amendments',
    icon: TagOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'birthdays',
    path: `${APP_PREFIX_PATH}/birthdays`,
    title: 'sidenav.pages.birthdays',
    icon: GiftOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'calendar',
    path: `${APP_PREFIX_PATH}/calendar`,
    title: 'sidenav.pages.calendar',
    icon: CalendarOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'management',
    path: ``,
    title: 'sidenav.pages.management',
    breadcrumb: false,
    submenu: [
      {
        key: 'users',
        path: `${APP_PREFIX_PATH}/usuario`,
        title: 'sidenav.pages.users',
        icon: UsergroupAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'permissions',
        path: `${APP_PREFIX_PATH}/permissions`,
        title: 'sidenav.pages.permissions',
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'social',
        path: `${APP_PREFIX_PATH}/social`,
        title: 'sidenav.pages.social',
        icon: LinkOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'log',
    path: `${APP_PREFIX_PATH}/log`,
    title: 'sidenav.pages.log',
    icon: UngroupOutlined,
    breadcrumb: false,
    submenu: [
      // {
      //   key: 'register',
      //   path: `${APP_PREFIX_PATH}/register`,
      //   title: 'sidenav.pages.register',
      //   icon: SettingOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: 'group',
      //       path: `${APP_PREFIX_PATH}/register/grupo/list/Grupo`,
      //       title: 'sidenav.pages.group',
      //       icon: PlusOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: 'category',
      //       path: `${APP_PREFIX_PATH}/register/categoria/list/Categoria`,
      //       title: 'sidenav.pages.category',
      //       icon: PlusOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: 'function',
      //       path: `${APP_PREFIX_PATH}/register/funcao/list/Funcao`,
      //       title: 'sidenav.pages.function',
      //       icon: PlusOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: 'occupation',
      //       path: `${APP_PREFIX_PATH}/register/ocupacao/list/Ocupacao`,
      //       title: 'sidenav.pages.occupation',
      //       icon: PlusOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: 'county',
      //       path: `${APP_PREFIX_PATH}/register/municipio/list/`,
      //       title: 'sidenav.pages.county',
      //       icon: PlusOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: 'office',
      //       path: `${APP_PREFIX_PATH}/register/cargo/list/Cargo`,
      //       title: 'sidenav.pages.office',
      //       icon: PlusOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: 'affiliation',
      //       path: `${APP_PREFIX_PATH}/register/partido/list/Partido`,
      //       title: 'sidenav.pages.affiliation',
      //       icon: PlusOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
