import authService from 'services/auth.service';
import { LoginInterface } from 'types/auth.interface';
import create from 'zustand';
import { AUTH_STORAGE, AUTH_TOKEN, USER_ID } from '../constants/AuthConstant';
import { persist } from 'zustand/middleware';

interface AuthState {
  loading: boolean;
  showMessage: boolean;
  message: string;
  redirect: string;
  token: string | null;
  userId: number | null;
  showAuthMessage(message: string): void;
  login(body: LoginInterface): Promise<void>;
  authenticated(token: string): void;
  showLoading(): void;
  logout(): void;
  hideAuthMessage(): void;
}
export const useAuth = create(
  persist<AuthState>(
    (set, get) => ({
      loading: false,
      message: '',
      showMessage: false,
      userId: Number(localStorage.getItem(USER_ID)),
      redirect: '',
      token: localStorage.getItem(AUTH_TOKEN),

      showAuthMessage: (message: string) => {
        set({ message, showMessage: true, loading: false });
      },
      showLoading: () => {
        set({ loading: true });
      },
      hideAuthMessage: () => {
        set({ message: '', showMessage: false });
      },
      authenticated: (token: string) => {
        set({ loading: false, redirect: '/app/home', token: token });
      },
      login: async (body: LoginInterface) => {
        set({ loading: true, showMessage: false });

        try {
          const result = await authService.login(body);

          set({ token: result.token, userId: result.uid });

          window.localStorage.setItem(AUTH_TOKEN, result.token);
        } catch (e: any) {
          set({ message: e?.response?.data?.message, showMessage: true });
        } finally {
          set({
            loading: false,
          });
        }
      },
      logout: () => {
        set({ token: null, userId: null });
        localStorage.removeItem(AUTH_TOKEN);
        window.location.href = '/auth/login';
      },
    }), {
    name: AUTH_STORAGE
  })
);
