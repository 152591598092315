import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useAuth } from 'stores/auth.store';

export const NavProfile = ({ signOut }) => {
  const { logout } = useAuth();

  const profileImg = '/img/avatars/thumb-1.jpg';
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            size={45}
            src={profileImg}
          />
          <div className="pl-3">
            <h4 className="mb-0">Charlie Howard</h4>
            <span className="text-muted">Frontend Developer</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item
            key={1}
            onClick={() => logout()}
          >
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sair</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown
      placement="bottomRight"
      overlay={profileMenu}
      trigger={['click']}
    >
      <Menu
        className="d-flex align-item-center"
        mode="horizontal"
        openKeys={['profile']}
      >
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
